<template>
  <div >
		<div v-html="content"></div>
	</div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return{
      content:''
    }
  },
  created(){
    this.getInfo()
  },
  methods:{
    getInfo(){
       this.$http({
          url:'https://rrhgadmin.renren885.com/sqx_fast/app/common/type/236',
          method:'get'
        }).then((res)=>{
          console.log(res);
           this.content = res.data.data.value;
           console.log(this.content);
        })
    }
  }
}
</script>

<style>

</style>
